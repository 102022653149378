<template>
  <nav class="bg-gray-800 p-4 flex justify-between items-center relative">
    <div class="flex items-center space-x-4">
      <Icon icon="solar:soundwave-bold-duotone" class="text-white text-4xl"></Icon>
      <span class="text-white text-2xl">Project Echo</span>
    </div>
    <div>
      <button v-if="!isAuthenticated" @click="login" class="text-black bg-white px-4 py-2 rounded">Login</button>
      <button v-else @click="logout" class="px-4 py-2 bg-white text-black rounded-md hover:bg-gray-200 transition-colors flex items-center">Logout</button>
    </div>
  </nav>
</template>

<script>
import { defineComponent } from 'vue';
import { Icon } from '@iconify/vue';

export default defineComponent({
  name: 'NavbarComponent',
  components: {
    Icon,
  },
  computed: {
    isAuthenticated() {
      return this.$auth0.isAuthenticated.value;
    },
  },
  methods: {
    login() {
      this.$auth0.loginWithRedirect();
    },
    logout() {
      this.$auth0.logout({ returnTo: window.location.origin });
    },
  },
});
</script>

<style scoped>
/* Add any scoped styles for the Navbar here */
</style>
