import { createAuth0 } from "@auth0/auth0-vue";

export const clientOptions = {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    scope: process.env.VUE_APP_AUTH0_SCOPE,
  },
  // cacheLocation: "localstorage",
  // useRefreshTokens: true,
};

export const auth0 = createAuth0(clientOptions);

export const getAccessToken = async () => {
  return await auth0.getAccessTokenSilently({
    // cacheMode: "on",
    authorizationParams: clientOptions.authorizationParams,
  });
};

export const fetchWithToken = async (url, options) => {
  const accessToken = await getAccessToken();
  options.headers = {
    ...options.headers,
    Authorization: `Bearer ${accessToken}`,
  };
  return fetch(url, options);
};
