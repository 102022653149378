<template>
  <div class="flex flex-col h-screen bg-white">
    <NavbarComponent />
    <ResponsiveLayout v-if="isAuthenticated">
      <ChatInterface />
    </ResponsiveLayout>
    <div v-else class="flex-1 flex flex-col items-center justify-center space-y-10 p-4">
      <div class="w-full max-w-2xl">
        <video class="w-full rounded-lg shadow-xl" controls preload="auto">
          <source src="https://project-echo.prosus-ai.com/resources/echo_demo.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
      <p class="text-lg md:text-xl text-gray-700">Please log in to access the application.</p>
    </div>
    <PWAInstallPrompt />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import NavbarComponent from './components/NavbarComponent.vue';
import ResponsiveLayout from './components/ResponsiveLayout.vue';
import ChatInterface from './components/ChatInterface.vue';
import PWAInstallPrompt from './components/PWAInstallPrompt.vue';

export default defineComponent({
  name: 'App',
  components: {
    NavbarComponent,
    ResponsiveLayout,
    ChatInterface,
    PWAInstallPrompt,
  },
  computed: {
    isAuthenticated() {
      return this.$auth0.isAuthenticated.value;
    },
  },
});
</script>

<style>
</style>
