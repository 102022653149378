<template>
  <div class="message-text" v-html="sanitizedContent"></div>
</template>

<script>
import { markdown } from '@/utils/marked';
import DOMPurify from 'dompurify';
import 'highlight.js/styles/github-dark.css';

export default {
  name: 'MarkdownComponent',
  props: {
    content: {
      type: String,
      required: true
    }
  },
  computed: {
    sanitizedContent() {
      try {
        const rawHtml = markdown.render(this.content);
        return DOMPurify.sanitize(rawHtml, {
          ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
            'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
            'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'span', 'img'],
          ALLOWED_ATTR: ['href', 'name', 'target', 'src', 'alt', 'class', 'title'],
          ADD_ATTR: ['target'],
        });
      } catch (error) {
        console.error('Error rendering markdown:', error);
        return '<p class="error">Error rendering content</p>';
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.addCopyButtons();
    });
  },
  updated() {
    this.$nextTick(() => {
      this.addCopyButtons();
    });
  },
  methods: {
    addCopyButtons() {
      const codeBlocks = document.querySelectorAll('.message-text pre.hljs');
      codeBlocks.forEach((block,) => {
        if (!block.querySelector('.copy-button')) {
          const button = document.createElement('button');
          button.className = 'copy-button';
          button.innerHTML = 'Copy';
          button.addEventListener('click', () => this.copyCode(block, button));
          block.style.position = 'relative';
          block.appendChild(button);
        }
      });
    },
    copyCode(block, button) {
      const code = block.querySelector('code').innerText;
      navigator.clipboard.writeText(code).then(() => {
        button.innerHTML = 'Copied!';
        setTimeout(() => {
          button.innerHTML = 'Copy';
        }, 2000);
      }, (err) => {
        console.error('Could not copy text: ', err);
        button.innerHTML = 'Error';
      });
    }
  }
}
</script>

<style>
.copy-button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px 10px;
  background-color: #2d333b;
  color: #c9d1d9;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s;
}

.hljs:hover .copy-button {
  opacity: 1;
}

.copy-button:hover {
  background-color: #444c56;
}

.message-text pre.hljs {
  position: relative;
  padding-top: 2.5em; /* Make room for the copy button */
}
</style>